/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/landing-pages/coworking";
// import Rental from "layouts/pages/landing-pages/rental";
// import AboutUs from "layouts/pages/company/about-us";
// import Pricing from "layouts/pages/company/pricing";
// import HelpCenter from "layouts/pages/support/help-center";
// import ContactUs from "layouts/pages/support/contact-us";
// import Faq from "layouts/pages/support/faq";
// import Privacy from "layouts/pages/support/privacy";

const routes = [
  {
    name: "Home",
    route: "/pages/landing-pages/coworking",
    icon: <Icon>dashboard</Icon>,
    component: <CoworkingPage />,
  },
  {
    name: "about us",
    description: "Canberra based specialists",
    icon: <Icon>view_day</Icon>,
    route: "/pages/company/about-us",
    // component: <AboutUs />,
  },
  {
    name: "quote",
    icon: <Icon>view_day</Icon>,
    collapse: [
      // {
      //   name: "about us",
      //   route: "/pages/company/about-us",
      //   component: <AboutUs />,
      // },
      // {
      //   name: "pricing",
      //   route: "/pages/company/pricing",
      //   component: <Pricing />,
      // },
    ],
  },
  {
    name: "resources",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "getting started",
        description: "All about overview, quick start, license and contents",
        // href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-kit/",
      },
      {
        name: "Skills",
        description: "Quality service that you can rely on",
        // href: "https://www.creative-tim.com/learning-lab/react/colors/material-kit/",
      },
      {
        name: "general repair",
        description: "General repair - improve quality of life",
        // href: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
      },
      {
        name: "Home improvement",
        description: "Check how we can improve your home",
        // href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
      },
    ],
  },
];

export default routes;
